import { categorySelectOption } from './categorySelectOption';

export function getOptionValueOrLabel(v: string) {
	const a = categorySelectOption.find((i) => i.label === v)?.value;

	if (a) {
		return a;
	}

	return categorySelectOption.find((i) => i.value === v)?.label;
}
